<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="加班单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="加班单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in this.$store.state.state"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="start_time">
                <el-date-picker
                    v-model="form.start_time"
                    align="right"
                    type="date"
                    placeholder="选择开始时间"
                    value-format="yyyy-MM-dd"
                    class="start_timec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="end_time">
                <el-date-picker
                    v-model="form.end_time"
                    align="right"
                    type="date"
                    placeholder="选择结束时间"
                    value-format="yyyy-MM-dd"
                    class="end_timec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请人" prop="create_staff">
                <el-select
                    v-model="form.create_staff"
                    placeholder="选择申请人"
                    class="create_staffc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加加班申请</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { staff_list_request } from '@/network/list.js'

export default {
    name: 'OverTimeSearchBarComponent',
    data() {
        return {
            form: {
                name: '',
                state: '',
                create_staff: '',
                start_time: '',
                end_time: '',
                page: 1,
            },
            AllStaff: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {
        add_auth: String,
        
    },
    created() {
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>
