<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="OverTime.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="OverTime.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="OverTime.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="OverTime.state===3" type="danger" size="mini">已驳回</el-tag>
                <el-button
                    v-if="(OverTime.state>0) && ($_has(print_auth))"
                    plain
                    circle
                    icon="el-icon-printer"
                    @click="clk_print()"
                    ></el-button
                >
            </template>
            <div>
                <el-form
                    :model="OverTime"
                    :rules="rules"
                    ref="OverTimeEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type"> 
                        <fieldset class="fieldsetc">
                            <legend class="legendc">加班信息</legend>
                            <el-form-item label="加班原因" prop="content">
                            <el-input type="textarea" :rows="3" v-model="OverTime.content">
                            </el-input>                    
                            </el-form-item>
                            <el-form-item label="加班小时" prop="duration">
                                <el-input-number
                                    v-model="OverTime.duration"
                                    :min="1.5"
                                    :step="0.5"
                                    :precision="1"
                                    :step-strictly="true"
                                    size="small"
                                    class="input_number"
                                ></el-input-number>
                            </el-form-item> 
                            <el-form-item label="开始时间" prop="start_time">
                                <el-date-picker
                                    v-model="OverTime.start_time"
                                    align="right"
                                    type="datetime"
                                    placeholder="选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    class="start_timec"
                                >
                                </el-date-picker>
                            </el-form-item>    
                            <el-form-item label="结束时间" prop="end_time">
                                <el-date-picker
                                    v-model="OverTime.end_time"
                                    align="right"
                                    type="datetime"
                                    placeholder="选择结束时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    class="end_timec"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </fieldset>
                        <common-upload-component
                            ref="upload"
                            :ffileList="OverTime.other"
                            upload_url="over_time/upload"
                        ></common-upload-component>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">加班信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="1"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item  :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>加班原因</span>
                                    </template>
                                    <span>{{OverTime.content}}</span>
                                </el-descriptions-item>                         
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>加班时长</span>
                                    </template>
                                    <span>{{OverTime.duration}}小时</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                    <template slot="label">
                                        <span>开始时间</span>
                                    </template>
                                    <span>{{OverTime.start_time}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                    <template slot="label">
                                        <span>结束时间</span>
                                    </template>
                                    <span>{{OverTime.end_time}}</span>
                                </el-descriptions-item>                            
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请人</span>
                                    </template>
                                    <span>{{OverTime.create_staff_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>申请时间</span>
                                    </template>
                                    <span>{{OverTime.apply_date}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <common-upload-component
                            v-show="OverTime.other.length"
                            ref="upload"
                            :ffileList="OverTime.other"
                            upload_url="over_time/upload"
                            :disabled="true"
                        ></common-upload-component>
                        <common-upload-component
                            v-show="AddFileDialogVisible"
                            ref="file_upload"
                            :ffileList="file_arr"
                            upload_url="over_time/upload"
                            title="补充附件"
                        ></common-upload-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <div v-if="AddFileDialogVisible === false">
                        <el-button
                            v-if="add_file_auth"
                            type="success"
                            plain
                            @click="add_file()"
                            >补充附件</el-button
                        >
                        <el-button
                            v-if="to_approval_auth"
                            type="success"
                            plain
                            @click="to_approval()"
                            >提交</el-button
                        >
                        <el-button
                            v-if="sel_approval_auth"
                            type="warning"
                            plain
                            @click="sel_approval()"
                            >查看审批</el-button
                        >
                        <el-button
                            v-if="withdraw_auth"
                            type="danger"
                            plain
                            @click="OverTimeWithdraw()"
                            >审批撤回</el-button
                        >
                        <el-button
                            v-if="edit_auth"
                            type="primary"
                            plain
                            @click="$emit('show_edit')"
                            >修改</el-button
                        >
                        <el-button
                            v-if="del_auth"
                            type="danger"
                            plain
                            @click="OverTimeDel()"
                            >删除</el-button
                        >
                        <el-button
                            plain
                            @click="closeDialog()"
                            >关闭</el-button
                        >
                    </div>
                    <div v-else>
                        <el-button
                            type="success"
                            plain
                            @click="submit_add_file()"
                            >确定</el-button
                        >
                        <el-button
                            plain
                            @click="exit_add_file()"
                            >取消</el-button
                        >
                    </div>
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('OverTimeEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('OverTimeEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('OverTimeEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="OverTime.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="over_timeApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
                <over-time-print-component
                    :show_print="show_print"
                    :data="OverTime"
                    :ApprovalData="ApprovalData"
                    @exit_print="show_print = false"
                >
                </over-time-print-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import OverTimePrintComponent from './OverTimePrintComponent'

import {
    over_time_add_request,
    over_time_del_request,
    over_time_edit_request,
    over_time_approval_request,
    over_time_sel_approval_request,
    over_time_to_approval_request,
    over_time_withdraw_request,
    over_time_add_file_request
} from '@/network/hrm/OverTime.js'

export default {
    name: 'OverTimeEditComponent',
    data() {
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            OverTime: {
                duration: '',
                start_time: '',
                end_time: '',
                content: '',
                other: [],
                print: [],
            },
            rules: {                
                duration: [
                    {
                        required: true,
                        message: '请填写加班时长',
                        trigger: 'blur',
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: '请选择开始时间',
                        trigger: 'blur',
                    },
                ],
                end_time: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '请填写加班原因',
                        trigger: 'blur',
                    },
                ],
            },
            ApprovalData: [],
            over_timeApprovalRequest:over_time_approval_request,
            show_print: false,
            AddFileDialogVisible: false,
            //补充附件
            file_arr: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.OverTime.name ? '加班单：' + this.OverTime.name : '加班单详情'
            } else if (this.show_type === 1) {
                return '添加加班申请'
            } else {
                return '修改加班申请'
            }
        },
    },
    methods: {
        closeDialog() {
            this.OverTime= {
                duration: '',
                start_time: '',
                end_time: '',
                content: '',
                other: [],
                print: []
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.OverTimeInsert(formName)
            })
        },
        OverTimeInsert(formName) {
            this.login_loading = true
            this.OverTime.other = this.$refs.upload.fileList
            over_time_add_request(this.OverTime)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.OverTimeEdit(formName)
            })
        },
        OverTimeEdit(formName) {
            this.login_loading = true
            this.OverTime.id = this.id
            this.OverTime.other = this.$refs.upload.fileList
            over_time_edit_request(this.OverTime)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.OverTime.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        OverTimeDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    over_time_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        to_approval() {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    over_time_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            over_time_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        OverTimeWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    over_time_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        clk_print() {
            this.login_loading = true
            over_time_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
            this.show_print = true
        },
        add_file() {
            this.AddFileDialogVisible = true
        },
        exit_add_file() {
            this.AddFileDialogVisible = false
            this.file_arr = []
        },
        submit_add_file() {
            let data = {}
            this.file_arr = this.$refs.file_upload.fileList
            data['id'] = this.id
            data['file'] = this.file_arr
            over_time_add_file_request(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('补充附件成功!')
                        this.$emit('frefresh')
                        this.AddFileDialogVisible = false
                        this.file_arr = []
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {

    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        over_time_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        add_file_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        print_auth: String
    },
    created() {
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonApprovalDialog,
        OverTimePrintComponent
    },
    watch: {
            over_time_details_data(newVal) {
            this.OverTime = newVal
            this.OverTime.id = this.id
        },
    },
}
</script>

<style> 
.el-date-editor.el-input {
    width: 100%;
}
.typec {
    width: 100%;
}
.input_number {
    width: 100%;
}
</style>